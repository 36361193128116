@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}
@media print {
  body,
  *,
  html {
    visibility: hidden;
  }
  .ps {
    overflow: scroll !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }
}
img {
  width: 100%;
}
.break-word {
  word-wrap: break-word;
}
button,
a,
.MuiMenuItem-root,
.MuiTypography-body1,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiChip-root,
.MuiFormHelperText-root {
  font-family: "Poppins", sans-serif !important;
}

.text-raleway {
  font-family: "Poppins", sans-serif !important;
}

.headerTab.MuiPaper-root {
  /* margin-bottom: 20px !important; */
  max-width: 320px !important;
}

.basic-multi-select {
  height: 40px !important;
}
/* Input Image */
.card-input-image {
  border: 2px dashed var(--primary);
  background: #f3ffec;
  transition: 0.3s;
  overflow: hidden;
  min-height: 10.5rem;
  max-height: 16rem;
  border-radius: 5px;
}
.card-input-image .preview {
  object-fit: contain;
  object-position: center;
}
.card-input-image .has-preview {
  background-color: rgba(0, 0, 0, 0.5);
}
.card-input-image input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.card-input-image p {
  color: var(--grey);
  font-size: 12px;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.d-flex {
  display: flex !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.flex-column {
  flex-direction: column !important;
}
.w-auto {
  width: auto !important;
}
.text-danger {
  color: #dc3545 !important;
}
.prev-next {
  border-radius: 4px;
  border: 1px solid #D8DCE0;
  padding: 6px 15px 6px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: auto !important;
  margin-left: 10px;
  margin-right: 10px;
  color: #5D5F61;
}
.prev-next:hover {
  color: #73BE44;
  border-color: #73BE44;
}
.prev-next-disabled {
  background-color: #F8F8F8;
  color: #CECECE;
  border-color: #F8F8F8;
  cursor: default !important;
}
.prev-next-disabled:hover {
  background-color: #F8F8F8;
  color: #CECECE;
  border-color: #F8F8F8;
}