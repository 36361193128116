@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  font-family: "Poppins", sans-serif !important;
}
@media print {
  body,
  *,
  html {
    visibility: hidden;
  }
  .ps {
    overflow: scroll !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }
}
img {
  width: 100%;
}
.break-word {
  word-wrap: break-word;
}
button,
a,
.MuiMenuItem-root,
.MuiTypography-body1,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiChip-root,
.MuiFormHelperText-root {
  font-family: "Poppins", sans-serif !important;
}

.text-raleway {
  font-family: "Poppins", sans-serif !important;
}

.headerTab.MuiPaper-root {
  /* margin-bottom: 20px !important; */
  max-width: 320px !important;
}

.basic-multi-select {
  height: 40px !important;
}
/* Input Image */
.card-input-image {
  border: 2px dashed var(--primary);
  background: #f3ffec;
  transition: 0.3s;
  overflow: hidden;
  min-height: 10.5rem;
  max-height: 16rem;
  border-radius: 5px;
}
.card-input-image .preview {
  object-fit: contain;
  object-position: center;
}
.card-input-image .has-preview {
  background-color: rgba(0, 0, 0, 0.5);
}
.card-input-image input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.card-input-image p {
  color: var(--grey);
  font-size: 12px;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.d-flex {
  display: flex !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.flex-column {
  flex-direction: column !important;
}
.w-auto {
  width: auto !important;
}
.text-danger {
  color: #dc3545 !important;
}
.prev-next {
  border-radius: 4px;
  border: 1px solid #D8DCE0;
  padding: 6px 15px 6px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto !important;
  margin-left: 10px;
  margin-right: 10px;
  color: #5D5F61;
}
.prev-next:hover {
  color: #73BE44;
  border-color: #73BE44;
}
.prev-next-disabled {
  background-color: #F8F8F8;
  color: #CECECE;
  border-color: #F8F8F8;
  cursor: default !important;
}
.prev-next-disabled:hover {
  background-color: #F8F8F8;
  color: #CECECE;
  border-color: #F8F8F8;
}
.card-buku-kas .container-icon {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #ddd;
}
.card-buku-kas .btn-edit {
  width: 25px;
  height: 25px;
}
.card-buku-kas .title,
.card-buku-kas .desc {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: "Roboto", sans-serif !important;
}
.card-buku-kas:hover .title {
  text-decoration: underline;
}
.logo-icon {
  width: 35px;
}

.img-profile {
  border-radius: 5px;
}

.logo-mobile {
  width: 150px;
  height: 36.67px;
}

.swal2-container {
  z-index: 1570 !important;
}

.searchbar {
  width: 500px !important;
  height: 46px;

  /* Neutral/10

FFFFFF
*/
  background: #ffffff !important ;
  /* Neutral/40

D8DCE0
*/
  border-radius: 5px !important;
}

.searchbar .MuiOutlinedInput-root .fieldset {
  border: none;
}

.date-input {
  padding: 20px 16px;
  grid-gap: 10px;
  gap: 10px;

  width: 263px;
  height: 54px;
  margin-top: 8px;

  /* Neutral/10

FFFFFF
*/
  background: #ffffff;
  /* Neutral/40

D8DCE0
*/
  border: 1px solid #c0c2c4;
  border-radius: 5px;
}

.dot {
  width: 4px;
  height: 4px;

  /* Black */

  background: #0a0a0a;
  border-radius: 100%;
}

.dot-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.dot-container img {
  width: 25px;
}

.dot-container :hover {
  cursor: pointer;
}

.dropdownhelper {
  position: absolute;
  margin: 0 auto;
  margin-left: 6px;
  margin-top: 6px;
}

.waktu-absensi-button-active {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 10px;
  gap: 10px;

  width: -moz-fit-content;

  width: fit-content;
  height: 28px;

  /* primary */

  background: #72be42;
  /* primary */

  border: 1px solid #72be42;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  ont-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  /* white */

  color: #ffffff;
}

.waktu-absensi-button {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 10px;
  gap: 10px;

  width: -moz-fit-content;

  width: fit-content;

  height: 28px;

  /* primary */

  background: #ffffff;
  /* primary */

  border: 1px solid #72be42;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  /* white */

  color: #72be42;
}

.tambah-button Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 10px 10px !important; */
  grid-gap: 25px !important;
  gap: 25px !important;

  width: 148px !important;
  height: 45px !important;

  /* primary */

  background: #72be42 !important;
  border-radius: 5px !important;

  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  /* identical to box height, or 21px */

  /* Neutral/10 */

  color: #ffffff !important;
}

.button-container-kehadiran {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.simpan-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  grid-gap: 8px;
  gap: 8px;

  width: 190px;
  height: 45px;

  /* primary */

  background: #ffffff !important;
  border-radius: 1px !important;
  border: 1px solid #72be42 !important;
  color: #72be42 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.batal-lembur-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  grid-gap: 8px;
  gap: 8px;

  width: 100%;
  height: 45px;

  /* primary */

  background: #ffffff !important;
  border-radius: 1px !important;
  border: 1px solid #72be42 !important;
  color: #72be42 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.no-shadow {
  box-shadow: none !important;
}

.tolak-lembur-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  grid-gap: 8px;
  gap: 8px;

  width: 100%;
  height: 45px;

  /* primary */

  background: #ffffff !important;
  border-radius: 1px !important;
  border: 1px solid #ea0000 !important;
  color: #ea0000 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.Hapus-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  grid-gap: 8px;
  gap: 8px;

  width: 190px;
  height: 45px;

  /* primary */
  border: 1px solid #72be42 !important;

  background: #72be42 !important;
  border-radius: 1px !important;
  color: #ffffff !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.atur_kehadiran_button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 30px;
}

.riwayat-gaji-card {
  max-height: 400px !important;
  margin-bottom: 30px !important;
}

.max-height-40px {
  max-height: 40px !important;
}

.half-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 40%;
}

.half-container-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 60%;
  position: relative;
}

.half-container h5 {
  margin-bottom: 20px;
}
.half-container h3 {
  margin-bottom: 50px;
}

.green-gaji-card {
  width: 200px;
  height: 171px;
  padding: 30px;

  /* Background */

  background: #f3ffec;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.green-gaji-card h3 {
  font-size: 20px;
}
.green-gaji-card h2 {
  ont-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 40px;
}

.green-gaji-card h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 10px;
  /* identical to box height, or 18px */

  /* Neutral/80 */

  color: #616161;
}

.lembur-button-active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  /* primary */

  color: #72be42;
  border-bottom: #72be42 1px solid;
}

.lembur-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  margin-top: 15px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  padding-bottom: 5px;
  /* identical to box height, or 175% */

  /* primary */

  color: #72be42;
  border-bottom: #72be42 1px solid;
  height: 46px;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
}

.lembur-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  padding-bottom: 5px;
  margin-top: 15px;

  /* identical to box height, or 175% */

  /* primary */

  width: 126px;
  height: 46px;

  color: #bbbbbb;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
}

.simple-card-content {
  padding-inline: 84px;
  padding-block: 30px;

  ont-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Black */

  color: #0a0a0a;
}

.simple-card-content h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 12px;
  /* identical to box height, or 21px */

  /* darkgrey */

  color: #bbbbbb;
}

.simple-card-content h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  /* Black */

  color: #0a0a0a;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.filter-black {
  filter: invert(0%) sepia(70%) saturate(4622%) hue-rotate(16deg)
    brightness(78%) contrast(92%) !important;
  color: #0a0a0a !important;
}

.time-picker-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 20px 16px !important;
  grid-gap: 10px !important;
  gap: 10px !important;

  width: 194px !important;
  height: 46px !important;

  /* field bg */

  background: #fbfbfb !important;
  /* field stroke */

  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  color: black !important;
}

.time-picker-container .border-bottom-color {
  background: #ea0000 !important;
  /* field stroke */
}

.form-container {
}

.form-container h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Black */

  color: #0a0a0a;
}

.form-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Black */

  color: #0a0a0a;
}

.form-container2 h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Black */

  color: #0a0a0a;
}

.form-container2 h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Black */

  color: #0a0a0a;
}

.modal-content {
  max-width: 405px;
}

.text-field-modifier {
  padding: 10px 16px;
  grid-gap: 10px !important;
  gap: 10px !important;
  width: 194px !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 16px !important;
}

.text-field-modifier-multiline {
  padding: 10px 16px;
  grid-gap: 10px !important;
  gap: 10px !important;
  width: 100%;
  height: 100%;
  background: #fbfbfb !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;

  border: 1px solid #e6e9ed !important;
}

.mt-7px {
  margin-top: 7px !important;
}

.render-input-text {
  margin-top: 32px;
  padding-left: 10px;
  font-weight: 600;
}

.atur-lembur-border {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px;
  margin-top: 5px;
}

.text-field-modifier-long {
  height: 40px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 10px !important;
}

.text-field-modifier-long {
  height: 40px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 10px !important;
}

.text-field-modifier-time {
  height: 40px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px 10px !important;
}

.mr-3px {
  margin-right: 3px !important;
}

.menu-option {
  height: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.color-green {
  color: #72be42;
}
.color-red {
  color: #ff5c58;
}

.color-blue {
  color: #6bbaeb;
}

.time-picker-long {
  padding: 10px 16px !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  max-width: 100% !important;
  border: red solid 2px !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
}

.text-field-modifier-medium {
  width: 100% !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 16px !important;
}

.text-field-modifier-mediumplus {
  width: 100% !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 16px !important;
}

.text-field-modifier-small {
  max-width: 100% !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 10px !important;
}

.sub-title-form {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-block: 30px;
  /* identical to box height, or 30px */

  /* Black */

  color: #0a0a0a;
}

.pl-20px {
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.pr-20px {
  padding-right: 20px !important;
  padding-left: 10px !important;
}

.pi-4 {
  padding-inline: 4% !important;
}

.delete-button {
  margin-top: 26px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff5c58;
  border: 1px solid #ff5c58;
  border-radius: 5px;
}

.delete-button img {
  height: 16px;
  width: 16px;
}

.delete-button Button {
  max-width: 46px !important;
  height: 46px;
}

.add-button {
  margin-top: 26px;

  /* dark primary */
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #72be42;

  border: 1px solid #72be42;
  border-radius: 5px;
  padding-inline: 5px;
}

.add-button Button {
  max-width: 46px !important;
  height: 46px;
}

.add-button img {
  height: 16px;
  width: 16px;
}

.karyawan-top-search {
  font-size: 10px !important;
}

.money-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;

  /* Background */

  background: #f3ffec;
  opacity: 0.5;
}

.up-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  /* Background */

  background: #d2f5bb;
  opacity: 0.5;
}

.down-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  /* Background */

  background: #f7eeee;
  opacity: 0.5;
}

.preview-kop-surat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin: 0 auto;
  margin-top: 15px;
}

.preview-kop-surat-block {
  width: 100%;
  height: 51px;
  background: #eae7e7;
  border-radius: 12px;
}

.preview-kop-surat-block-mini {
  width: 25%;
  height: 51px;
  background: #eae7e7;
  border-radius: 12px;
  margin-top: 50px;
  margin-bottom: 50px;

  align-self: flex-end;
}

.preview-kop-surat img {
  width: 100%;
  max-height: 150px;
}

.MuiTableCell-root {
  word-break: normal !important;
  font-family: "Poppins", sans-serif !important;
}

.MuiTable-root.buku-kas-table {
  min-width: 800px;
}

.button-purple {
  margin-bottom: 10px;
  box-shadow: none !important;
  transition: 0.2s;
  background-color: #7066eb !important;
  color: #fff !important;
}
.button-purple.opacity {
  background-color: rgba(112, 102, 235, 0.15) !important;
  color: #7066eb !important;
}
.button-purple:hover {
  background-color: rgba(112, 102, 235, 0.4) !important;
}
.button-purple .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.button-danger {
  margin-bottom: 10px;
  box-shadow: none !important;
  background-color: rgba(239, 79, 79, 100) !important;
  color: #fff !important;
  transition: 0.2s;
}
.button-danger.opacity {
  background-color: rgba(239, 79, 79, 0.55) !important;
  color: #fff !important;
}
.button-danger:hover {
  background-color: rgba(239, 79, 79, 0.9) !important;
}
.button-danger .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.button-grey {
  margin-bottom: 10px;
  box-shadow: none !important;
  background: #d5d5d5;
  transition: 0.2s;
}
.button-grey:hover {
  background-color: #b0b0b0 !important;
}
.button-grey .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.button-blue {
  margin-bottom: 10px;
  box-shadow: none !important;
  background: rgba(112, 102, 235, 0.9) !important;
  transition: 0.2s !important;
  color: white !important;
}
.button-blue:hover {
  background-color: #9d96f4 !important;
}
.button-blue .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.button-green {
  margin-bottom: 10px;
  box-shadow: none !important;
  background-color: #16c79a !important;
  transition: 0.2s;
  color: #fff !important;
}

.button-green.opacity {
  background-color: #b2eadc !important;
}
.button-green:hover {
  background-color: #1ac79c !important;
}
.button-green .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.button-orange {
  margin-bottom: 10px;
  box-shadow: none !important;
  transition: 0.2s;
  background-color: rgba(242, 157, 86, 100) !important;
  color: #fff !important;
}
.button-orange.opacity {
  background-color: rgba(242, 157, 86, 0.15) !important;
  color: #f29d56 !important;
}
.button-orange:hover {
  background-color: rgba(242, 157, 86, 0.4) !important;
}
.button-orange .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.btn-nomor-soal {
  display: flex;
  background: #d5d5d5;
  align-items: center;
  justify-content: center;
  color: #fff;
  max-width: 35px;
  height: 34px;
  border-radius: 5px;
  width: 34px;
  border: none;
  cursor: pointer;
}
.btn-nomor-soal:hover {
  background: #b0b0b0;
}
.btn-nomor-soal.active {
  background: #b0b0b0;
}
.btn-nomor-soal.done {
  background: #fd591d;
}
.btn-nomor-soal.wrong {
  background: #ef4f4f;
}
.btn-nomor-soal.done.active {
  background: #d0cbff;
}
.btn-nomor-soal.wrong.active {
  background: #ff7a7a;
}
.button-outline-error {
  border: 1px solid #ef4f4f !important;
  color: #ef4f4f !important;
}

/* BUTTON COLOR */
.btn-blue {
  background-color: #0094ff !important;
}

.button-mod {
  border-radius: 20px !important;
  height: 35px !important;
  width: 300px;
}

.button-try {
  border-radius: 20px !important;
  height: 30px !important;
  width: 130px;
}

.text-p {
  font-weight: 450;
  text-transform: capitalize;
}
/* Button upload profile */
.btn-foto-profile {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 10%) !important;
  border-radius: 100% !important;
}
.btn-foto-profile:hover {
  background: rgb(0 0 0 / 50%) !important;
}

.no-border {
  border: 0px solid rgba(224, 224, 224, 1) !important;
}

.badge-diskon {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
  background-color: #ebb666 !important;
  transition: 0.2s;
  color: #fff !important;
}

/* Buton upload file */
.container-btn-file {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 5%) !important;
  position: absolute !important;
  left: 0;
  top: 0;
}

.container-btn-file input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0px;
  font-size: 15px;
  color: rgb(153, 153, 153);
}

.container-btn-file .button-wrap {
  position: relative;
}

.container-btn-file .new-button {
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #9c27b0;
  font-size: 16px;
  color: #fff;
}

.button-primary {
  margin-bottom: 10px;
  box-shadow: none !important;
  transition: 0.2s;
  background-color: #fd591d !important;
  color: #fff !important;
}

.button-primary.opacity {
  background-color: #ffa07d !important;
}

.button-primary:hover {
  background-color: #ffa07d !important;
}

.button-primary .text-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.btn-warna {
  border-radius: 3px;
  border: 0;
  color: #fff;
  padding: 5px 15px 5px 15px;
  cursor: pointer;
  flex: 2 1;
}
.btn-warna.merah {
  background: #ff4646;
}
.btn-warna.kuning {
  background: #ffd151;
}
.btn-warna.hijau {
  background: #16c79a;
}
.btn-warna.ungu {
  background: #a79dff;
}
.container-btn-del-file {
  width: 25px;
  height: 25px;
  position: absolute !important;
  right: -5px;
  background-color: #ff4646 !important;
  padding: 0 !important;
  min-width: 25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 25px !important;
  top: -5px;
}

.btn-formula {
  border: none;
  background: none;
  margin-left: 20px;
  margin-top: 8px;
  position: absolute;
  cursor: pointer;
}

.input-editor .ql-toolbar.ql-snow {
  padding-left: 40px;
}

.container-btn-del-file {
  width: 25px;
  height: 25px;
  position: relative !important;
  right: -10px;
  background-color: #ff4646 !important;
  padding: 0 !important;
  min-width: 25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 25px !important;
  top: -15px;
}

#arrowAnim {
  width: 50vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 5vw;
  height: 5vw;
  border: 2.5vw solid;
  border-color: #73be44 transparent transparent #73be44;
  transform: rotate(-45deg);
}

.arrowSliding {
  position: absolute;
  animation: slide 4s linear infinite;
}

.delay1 {
  animation-delay: 1s;
}
.delay2 {
  animation-delay: 2s;
}
.delay3 {
  animation-delay: 3s;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-15vw);
  }
}

.wrap-info {
  display: flex;
  justify-content: space-between;
}

div.wrap-info .icon-info {
  color: red;
  cursor: pointer;
}

