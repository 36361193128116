.swal2-container {
  z-index: 1570 !important;
}

.searchbar {
  width: 500px !important;
  height: 46px;

  /* Neutral/10

FFFFFF
*/
  background: #ffffff !important ;
  /* Neutral/40

D8DCE0
*/
  border-radius: 5px !important;
}

.searchbar .MuiOutlinedInput-root .fieldset {
  border: none;
}

.date-input {
  padding: 20px 16px;
  gap: 10px;

  width: 263px;
  height: 54px;
  margin-top: 8px;

  /* Neutral/10

FFFFFF
*/
  background: #ffffff;
  /* Neutral/40

D8DCE0
*/
  border: 1px solid #c0c2c4;
  border-radius: 5px;
}

.dot {
  width: 4px;
  height: 4px;

  /* Black */

  background: #0a0a0a;
  border-radius: 100%;
}

.dot-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.dot-container img {
  width: 25px;
}

.dot-container :hover {
  cursor: pointer;
}

.dropdownhelper {
  position: absolute;
  margin: 0 auto;
  margin-left: 6px;
  margin-top: 6px;
}

.waktu-absensi-button-active {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;

  width: fit-content;
  height: 28px;

  /* primary */

  background: #72be42;
  /* primary */

  border: 1px solid #72be42;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  ont-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  /* white */

  color: #ffffff;
}

.waktu-absensi-button {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;

  width: fit-content;

  height: 28px;

  /* primary */

  background: #ffffff;
  /* primary */

  border: 1px solid #72be42;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  /* white */

  color: #72be42;
}

.tambah-button Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 10px 10px !important; */
  gap: 25px !important;

  width: 148px !important;
  height: 45px !important;

  /* primary */

  background: #72be42 !important;
  border-radius: 5px !important;

  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  /* identical to box height, or 21px */

  /* Neutral/10 */

  color: #ffffff !important;
}

.button-container-kehadiran {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.simpan-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  gap: 8px;

  width: 190px;
  height: 45px;

  /* primary */

  background: #ffffff !important;
  border-radius: 1px !important;
  border: 1px solid #72be42 !important;
  color: #72be42 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.batal-lembur-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  gap: 8px;

  width: 100%;
  height: 45px;

  /* primary */

  background: #ffffff !important;
  border-radius: 1px !important;
  border: 1px solid #72be42 !important;
  color: #72be42 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.no-shadow {
  box-shadow: none !important;
}

.tolak-lembur-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  gap: 8px;

  width: 100%;
  height: 45px;

  /* primary */

  background: #ffffff !important;
  border-radius: 1px !important;
  border: 1px solid #ea0000 !important;
  color: #ea0000 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.Hapus-button Button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  gap: 8px;

  width: 190px;
  height: 45px;

  /* primary */
  border: 1px solid #72be42 !important;

  background: #72be42 !important;
  border-radius: 1px !important;
  color: #ffffff !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.atur_kehadiran_button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.riwayat-gaji-card {
  max-height: 400px !important;
  margin-bottom: 30px !important;
}

.max-height-40px {
  max-height: 40px !important;
}

.half-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 40%;
}

.half-container-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 60%;
  position: relative;
}

.half-container h5 {
  margin-bottom: 20px;
}
.half-container h3 {
  margin-bottom: 50px;
}

.green-gaji-card {
  width: 200px;
  height: 171px;
  padding: 30px;

  /* Background */

  background: #f3ffec;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.green-gaji-card h3 {
  font-size: 20px;
}
.green-gaji-card h2 {
  ont-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 40px;
}

.green-gaji-card h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 10px;
  /* identical to box height, or 18px */

  /* Neutral/80 */

  color: #616161;
}

.lembur-button-active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  /* primary */

  color: #72be42;
  border-bottom: #72be42 1px solid;
}

.lembur-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  margin-top: 15px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  padding-bottom: 5px;
  /* identical to box height, or 175% */

  /* primary */

  color: #72be42;
  border-bottom: #72be42 1px solid;
  height: 46px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
}

.lembur-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  padding-bottom: 5px;
  margin-top: 15px;

  /* identical to box height, or 175% */

  /* primary */

  width: 126px;
  height: 46px;

  color: #bbbbbb;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
}

.simple-card-content {
  padding-inline: 84px;
  padding-block: 30px;

  ont-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Black */

  color: #0a0a0a;
}

.simple-card-content h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 12px;
  /* identical to box height, or 21px */

  /* darkgrey */

  color: #bbbbbb;
}

.simple-card-content h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  /* Black */

  color: #0a0a0a;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.filter-black {
  filter: invert(0%) sepia(70%) saturate(4622%) hue-rotate(16deg)
    brightness(78%) contrast(92%) !important;
  color: #0a0a0a !important;
}

.time-picker-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 20px 16px !important;
  gap: 10px !important;

  width: 194px !important;
  height: 46px !important;

  /* field bg */

  background: #fbfbfb !important;
  /* field stroke */

  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  color: black !important;
}

.time-picker-container .border-bottom-color {
  background: #ea0000 !important;
  /* field stroke */
}

.form-container {
}

.form-container h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Black */

  color: #0a0a0a;
}

.form-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Black */

  color: #0a0a0a;
}

.form-container2 h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Black */

  color: #0a0a0a;
}

.form-container2 h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Black */

  color: #0a0a0a;
}

.modal-content {
  max-width: 405px;
}

.text-field-modifier {
  padding: 10px 16px;
  gap: 10px !important;
  width: 194px !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 16px !important;
}

.text-field-modifier-multiline {
  padding: 10px 16px;
  gap: 10px !important;
  width: 100%;
  height: 100%;
  background: #fbfbfb !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;

  border: 1px solid #e6e9ed !important;
}

.mt-7px {
  margin-top: 7px !important;
}

.render-input-text {
  margin-top: 32px;
  padding-left: 10px;
  font-weight: 600;
}

.atur-lembur-border {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 12px;
  margin-top: 5px;
}

.text-field-modifier-long {
  height: 40px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 10px !important;
}

.text-field-modifier-long {
  height: 40px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 10px !important;
}

.text-field-modifier-time {
  height: 40px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px 10px !important;
}

.mr-3px {
  margin-right: 3px !important;
}

.menu-option {
  height: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.color-green {
  color: #72be42;
}
.color-red {
  color: #ff5c58;
}

.color-blue {
  color: #6bbaeb;
}

.time-picker-long {
  padding: 10px 16px !important;
  gap: 10px !important;
  max-width: 100% !important;
  border: red solid 2px !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
}

.text-field-modifier-medium {
  width: 100% !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 16px !important;
}

.text-field-modifier-mediumplus {
  width: 100% !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 10px 16px !important;
}

.text-field-modifier-small {
  max-width: 100% !important;
  height: 46px !important;
  background: #fbfbfb !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 10px !important;
}

.sub-title-form {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-block: 30px;
  /* identical to box height, or 30px */

  /* Black */

  color: #0a0a0a;
}

.pl-20px {
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.pr-20px {
  padding-right: 20px !important;
  padding-left: 10px !important;
}

.pi-4 {
  padding-inline: 4% !important;
}

.delete-button {
  margin-top: 26px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff5c58;
  border: 1px solid #ff5c58;
  border-radius: 5px;
}

.delete-button img {
  height: 16px;
  width: 16px;
}

.delete-button Button {
  max-width: 46px !important;
  height: 46px;
}

.add-button {
  margin-top: 26px;

  /* dark primary */
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #72be42;

  border: 1px solid #72be42;
  border-radius: 5px;
  padding-inline: 5px;
}

.add-button Button {
  max-width: 46px !important;
  height: 46px;
}

.add-button img {
  height: 16px;
  width: 16px;
}

.karyawan-top-search {
  font-size: 10px !important;
}

.money-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;

  /* Background */

  background: #f3ffec;
  opacity: 0.5;
}

.up-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  /* Background */

  background: #d2f5bb;
  opacity: 0.5;
}

.down-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  /* Background */

  background: #f7eeee;
  opacity: 0.5;
}

.preview-kop-surat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: fit-content;
  gap: 15px;
  margin: 0 auto;
  margin-top: 15px;
}

.preview-kop-surat-block {
  width: 100%;
  height: 51px;
  background: #eae7e7;
  border-radius: 12px;
}

.preview-kop-surat-block-mini {
  width: 25%;
  height: 51px;
  background: #eae7e7;
  border-radius: 12px;
  margin-top: 50px;
  margin-bottom: 50px;

  align-self: flex-end;
}

.preview-kop-surat img {
  width: 100%;
  max-height: 150px;
}
